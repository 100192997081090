import React from 'react'

export default props => {
    // const [loading, setLoading] = React.useState(false)
    const handleSubmit = e => {
        e.preventDefault()
    }

    return (
        <div className='mx-auto max-w-2xl mt-8 sm:mt-24 px-4'>
            <h1 className='sm:text-center sm:text-2xl font-semibold'>Validate Your Podcast Feed</h1>
            <form className='flex flex-col w-full' onSubmit={handleSubmit}>
                <label className='w-full mt-2 sm:mt-4'>
                    <input
                        type='url'
                        name='url'
                        className='form-input border-gray-200 w-full'
                        placeholder='https://your-rss-feed.com'
                    />
                </label>
            </form>
        </div>
    )
}
